<template>
  <div class="mt-down-header">
    <!-- breadcrumb -->
    <b-breadcrumb :items="items" class="my-bread">位置：</b-breadcrumb>
    <!-- breadcrumb end -->

    <div class="main-layout">
      <!-- list banner -->
      <div class="list-banner">
        <img src="@/assets/img/list-0@2x.png" alt>
        <div class="content flex-center-column">
          <h3>想要一份量身定制的策略吗?</h3>
          <p>那么你还在等什么?点击下方的按钮立即定制吧</p>
        </div>
      </div>
      <!-- list banner end-->

      <!-- question list -->
      <div class="question-list mt-30 c-border">
        <h4 class="mb-30">问卷调查</h4>
        <form action>
          <ol>
            <li v-for="(item,index) in questions" :key="index" class="item">
              <p>{{ item.question }}</p>
              <b-form-group>
                <b-form-radio-group
                  v-model="item.selected"
                  class="mr-60"
                  :options="item.options"
                  plain
                  :name="item.id"
                />
              </b-form-group>
            </li>
          </ol>
          <div class="flex-common mt-60">
            <b-button variant="outline-primary" class="form-button" @click="submitForm">下一步</b-button>
          </div>
        </form>
      </div>
      <!-- question list end -->
    </div>
  </div>
</template>

<script>
import '../../assets/style/common.scss'

export default {
  data() {
    return {
      items: [{
        text: '主页',
        href: '/home'
      }, {
        text: '问卷调查',
        active: true
      }],
      questions: [
        {
          index: 0,
          id: 1,
          question:
            '您是否想要某种数据但不知道从哪里找，或者数据过于分散、零散难以整理运用？',
          selected: true,
          options: [
            { text: '是', value: 'true' },
            { text: '否', value: 'false' }
          ],
          suggestions: [{ name: '数据商城' }]
        },
        {
          index: 1,
          id: 2,
          question:
            '您是否因数据太多太杂乱而难以分析运用，希望能简单直观地获得隐藏在数据中的洞见？',
          selected: false,
          options: [{ text: '是', value: true }, { text: '否', value: false }],
          suggestions: [{ name: '另类数据' }]
        },
        {
          index: 2,
          id: 3,
          question:
            '您是否需要阅读大量文本，而却因其中有效信息占比较少而浪费大量时间？',
          selected: '',
          options: [{ text: '是', value: true }, { text: '否', value: false }],
          suggestions: [{ name: 'NLP' }]
        },
        {
          index: 3,
          id: 4,
          question:
            '您是否希望能获得关于公司更全面、更深度的信息，而不仅仅是“表面上的”工商、财报、股价数据，帮助您发现“水面上的冰山之下”的公司隐藏的风险和价值点？',
          selected: '',
          options: [{ text: '是', value: true }, { text: '否', value: false }],
          suggestions: [{ name: '微信公众号关注' }]
        },
        {
          index: 4,
          id: 5,
          question:
            '您是否缺少投资研究的时间和有效方法，希望能够通过大数据自动地选出优良股、排除垃圾股？',

          selected: '',
          options: [{ text: '是', value: true }, { text: '否', value: false }],
          suggestions: [{ name: '个股深度报告' }]
        },
        {
          index: 5,
          id: 6,
          question:
            '您是否希望借鉴北美对冲基金最前沿的量化投资算法，将您的投资策略回报率提高5-10%，同时提高股价预测精度、动态降低风险、规避熊市冲击？',
          selected: '',
          options: [{ text: '是', value: true }, { text: '否', value: false }],
          suggestions: [{ name: '策略商城' }]
        },
        {
          index: 6,
          id: 7,
          question:
            '您是否已经在某些项目中引入大数据和人工智能技术，但是在深度学习、NLP自然语言处理、模型过拟合失效等关键技术点上遇到瓶颈，项目效果不如预期？',
          selected: '',
          options: [{ text: '是', value: true }, { text: '否', value: false }],
          suggestions: [{ name: '技术咨询' }]
        }
      ]
    }
  },

  mounted() {

  },

  methods: {
    submitForm: function() {
      console.log('in sub')
      const formData = {
        que_selected: this.questions
      }

      localStorage.setItem('que_selected_by_user', JSON.stringify(formData))

      this.$router.push('/dss')
    }
  }
}
</script>

<style lang="scss">
.list-banner {
  // padding: 20px;
  font-family: PingFang-SC-Regular;
  color: rgba(255, 255, 255, 1);
  font-weight: 400;
  background-size: 100% 100%;
  position: relative;
  letter-spacing: 2px;

  img {
    width: 100%;
    height: 100%;
  }
  h3 {
    font-size: 36px;
  }
  p {
    font-size: 24px;
  }

  div.content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.main-layout {
  padding: 0 105px;
}

.question-list {
  padding: 30px;
  margin-bottom: 30px;
  // max-width:70%;

 .form-button {
   margin-left: 20px;
 }
}
</style>
